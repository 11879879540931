"use client";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Link from "next/link";

import DropDown from "@/components/DropDown";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import IconArrowRight from "@/components/Icon/IconArrowRight.svg";
import IconArrowLeft from "@/components/Icon/IconArrowLeft.svg";
import IconArrowCaretDown from "@/components/Icon/IconArrowCaretDown.svg";
import Image from "next/image";

import fetchVehicles from "@/lib/typesense/vehicles/fetchVehicles";
import { getAccessoriesQuery, setAccessoriesQuery } from "@/utils/common";

export default function Component({ open, onClose, onCloseNavigation }) {
  const router = useRouter();
  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");

  const [yearOptions, setYearOptions] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const [vehicle, setVehicle] = useState({});

  const handleBackToMenu = () => {
    document.querySelector(`#menu-bar`)?.classList.add("show-menu");
    document.querySelector(`#menu-bar`)?.classList.remove("hidden-menu");
    onClose();
  };

  const handleSearchAccessories = e => {
    e.preventDefault();
    onCloseNavigation(false);
    setYearValue("");
    setMakeValue("");
    setModelValue("");
    setVehicle({});
    setMakeOptions([]);
    setModelOptions([]);
    if (!vehicle.vehicleNumber) router.push(`/accessories`);
    else router.push(`/accessories?vehicle_ids=${vehicle.vehicleNumber}`);
  };

  const fetchYearData = async () => {
    const response = await fetchVehicles({
      facet_by: "year",
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => b.label - a.label);
      setYearOptions(options);
    }
  };

  const fetchMakeData = async yearValue => {
    const response = await fetchVehicles({
      facet_by: "make",
      filter_by: `year:${yearValue}`,
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      setMakeOptions(options);
    }
  };
  const fetchModelData = async (yearValue, makeValue) => {
    const response = await fetchVehicles({
      facet_by: "model",
      filter_by: `year:${yearValue} && make:${makeValue}`,
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      setModelOptions(options);
    }
  };

  const fetchVehicleData = async (yearValue, makeValue, modelValue) => {
    const response = await fetchVehicles({
      filter_by: `year:${yearValue} && make:${makeValue} && model:${modelValue}`,
      include_fields: "",
    });
    if (response.hits.length > 0) {
      const searchedVehicle =
        response.hits.find(
          vehicle =>
            vehicle.document.year === yearValue &&
            vehicle.document.make === makeValue &&
            vehicle.document.model === modelValue
        )?.document || {};

      setVehicle(searchedVehicle);
      const newVehicle = searchedVehicle;
      const currentHistories = getAccessoriesQuery().histories || [];

      setAccessoriesQuery({
        vehicleNumber: newVehicle.vehicleNumber,
        histories: [
          ...currentHistories,
          {
            id: newVehicle.id,
            name: newVehicle.name,
            make: newVehicle.make,
            model: newVehicle.model,
            year: newVehicle.year,
            vehicleNumber: newVehicle.vehicleNumber,
          },
        ],
      });
    }
  };

  useEffect(() => {
    fetchYearData();
  }, []);

  return (
    <div
      id="vehicles-menu"
      className={classNames(styles.subMenu, styles.subMenu1, open ? styles["show-menu"] : styles["hidden-menu"])}
    >
      <div
        className="w-full py-5 flex lg:hidden items-center gap-2 px-5 border-b-[1px] border-grey cursor-pointer"
        onClick={handleBackToMenu}
      >
        <Image src={IconArrowLeft.src} width="16" height="16" alt="IconArrowLeft" />
        <p className={classNames("text-sm font-bold font-roboto")}>Back</p>
      </div>
      <div className="w-full h-fit flex flex-col lg:flex-row justify-between items-start lg:items-center gap-2 px-6 py-3 lg:py-7">
        <h5 className="ml-0 lg:ml-2">Search Parts</h5>
        <Link href="/accessories" className="font-bold text-sm" onClick={() => onCloseNavigation(false)}>
          View all
        </Link>
      </div>
      <div className="w-full px-2 lg:px-7">
        <form
          className="w-full flex flex-col gap-4 py-[26px] lg:py-[30px] px-[16px] lg:px-8 bg-black rounded-[4px]"
          onSubmit={handleSearchAccessories}
        >
          <p className="text-gold text-lg font-bold">Search parts by vehicle</p>
          <p className="text-sm text-grey">Add your motorcycle details here to shop products that fit your bike.</p>
          <div className="w-full flex flex-col gap-4">
            <DropDown
              className="bg-white w-full"
              size={56}
              name="year"
              placeholder="Year"
              value={yearValue}
              options={yearOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width={10} height={10} alt="IconArrowCaretDown" />}
              onChange={e => {
                setYearValue(e.target.value);
                fetchMakeData(e.target.value);
              }}
            />
            <DropDown
              className="bg-white w-full"
              size={56}
              name="make"
              placeholder="Make"
              value={makeValue}
              options={makeOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width={10} height={10} alt="IconArrowCaretDown" />}
              onChange={e => {
                setMakeValue(e.target.value);
                fetchModelData(yearValue, e.target.value);
              }}
              disabled={!yearValue}
            />
            <DropDown
              className="bg-white w-full"
              size={56}
              name="model"
              placeholder="Models"
              value={modelValue}
              options={modelOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width={10} height={10} alt="IconArrowCaretDown" />}
              onChange={e => {
                setModelValue(e.target.value);
                fetchVehicleData(yearValue, makeValue, e.target.value);
              }}
              disabled={!makeValue}
            />
          </div>
          <button
            type="submit"
            className="w-full rounded-[4px] py-3 text-base border-[1px] border-grey bg-transparent text-white font-bold mb-3"
          >
            SHOP NOW
          </button>
        </form>
      </div>
      <div className="mt-6 lg:mt-[56px]">
        <p className="px-6 lg:px-7 mb-1 text-grey text-sm">Quick Links</p>
        <div className={classNames("w-full px-6 lg:px-7 hover:bg-light-grey")}>
          <Link href="/accessories" onClick={() => onCloseNavigation(false)}>
            <div className="w-full h-[56px] flex items-center gap-4 border-b-[1px] border-grey-100 justify-between">
              <p className="text-base font-[500]">Shop all parts & accessories</p>
              <Image src={IconArrowRight.src} width="24" height="24" alt="IconArrowRight" />
            </div>
          </Link>
        </div>
        <div className={classNames("w-full px-6 lg:px-7 hover:bg-light-grey")}>
          <Link href="/vehicles" onClick={() => onCloseNavigation(false)}>
            <div className="w-full h-[56px] flex items-center gap-4 border-b-[1px] border-grey-100 justify-between">
              <p className="text-base font-[500]">Vehicle Directory</p>
              <Image src={IconArrowRight.src} width="24" height="24" alt="IconArrowRight" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
