"use client";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Link from "next/link";

import NewBikesMenu from "@/components/Layout/Navigation/NewBikesMenu";
import UsedBikesMenu from "@/components/Layout/Navigation/UsedBikesMenu";
import RoadGearMenu from "@/components/Layout/Navigation/RoadGearMenu";
import MXGearMenu from "@/components/Layout/Navigation/MXGearMenu";
import AdventureGearMenu from "@/components/Layout/Navigation/AdventureGearMenu";
import CategotiesMenu from "@/components/Layout/Navigation/CategotiesMenu";
import VehiclesMenu from "@/components/Layout/Navigation/VehiclesMenu";
import BrandsMenu from "@/components/Layout/Navigation/BrandsMenu";
import ContactMenu from "@/components/Layout/Navigation/ContactMenu";
import MoreInformationMenu from "@/components/Layout/Navigation/MoreInformationMenu";

import IconCloseWhite from "@/components/Icon/IconCloseWhite.svg";
import IconHamburger from "@/components/Icon/IconHamburger.svg";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";

const NEW_BIKES_MENU = "new-bikes-menu";
const USED_BIKES_MENU = "used-bikes-menu";
const SELL_YOUR_MOTORCYCLE = "sell-your-motorcycle";
const SERVICE_CENTERS_MENU = "service-centers-menu";
const ROAD_GEAR_MENU = "road-gear-menu";
const MX_GEAR_MENU = "mx-gear-menu";
const ADVENTURE_GEAR_MENU = "adventure-gear-menu";
const CATEGORIES_MENU = "categories-menu";
const VEHICLES_MENU = "vehicles-menu";
const OEM = "oem";
const BRANDS_MENU = "brands-menu";
const CONTACT_MENU = "contact-menu";
const MORE_MENU = "more-menu";

export default function Component() {
  const navigationRef = useRef(null);
  const [showNavigation, setShowNavigation] = useState(false);

  const [subMenu, setSubMenu] = useState();

  const handleShowNavigation = data => {
    if (data) {
      document.querySelector(`#menu-bar`)?.classList.add("show-menu");
      document.querySelector(`#menu-bar`)?.classList.remove("hidden-menu");
    }
    setShowNavigation(data);
    setSubMenu("");
    if (data) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  const menu = [
    {
      title: "Motorcycles",
      subMenu: [
        {
          title: "New Motorcycles",
          name: NEW_BIKES_MENU,
        },
        {
          title: "Used Motorcycles",
          name: USED_BIKES_MENU,
        },
        {
          title: "Sell Your Motorcycle",
          name: SELL_YOUR_MOTORCYCLE,
          link: "/used-bikes#sell",
        },
        {
          title: "Service Centers",
          name: SERVICE_CENTERS_MENU,
          link: "/service",
        },
      ],
    },
    {
      title: "Riding Gear",
      subMenu: [
        {
          title: "Road Gear",
          name: ROAD_GEAR_MENU,
        },
        {
          title: "MX Gear",
          name: MX_GEAR_MENU,
        },
        {
          title: "Adventure Gear",
          name: ADVENTURE_GEAR_MENU,
        },
      ],
    },
    {
      title: "Parts & Accessories",
      subMenu: [
        {
          title: "Shop by Category",
          name: CATEGORIES_MENU,
        },
        {
          title: "Search by Vehicle",
          name: VEHICLES_MENU,
        },
        {
          title: "OEM",
          name: OEM,
          link: "https://parts.bikebiz.com.au/",
        },
      ],
    },
    {
      title: "General Information",
      subMenu: [
        {
          title: "Brands",
          name: BRANDS_MENU,
        },
        {
          title: "Contact",
          name: CONTACT_MENU,
        },
        {
          title: "More Information",
          name: MORE_MENU,
        },
      ],
    },
  ];

  const handleClick = item => {
    setSubMenu(item);
    document.querySelector(`#menu-bar`)?.classList.remove("show-menu");
    document.querySelector(`#menu-bar`)?.classList.add("hidden-menu");
  };

  useEffect(() => {
    if (!showNavigation) {
      const subMenuItems = document.querySelectorAll(`.sub-menu-item`);
      subMenuItems.forEach(item => {
        item.classList.remove("show-menu");
        item.classList.add("hidden-menu");
      });
      const childSubMenuItems = document.querySelectorAll(`.child-sub-menu`);
      childSubMenuItems.forEach(item => {
        item.classList.remove("show-menu");
        item.classList.add("hidden-menu");
      });
    }
  }, [showNavigation]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (navigationRef.current && !navigationRef.current.contains(event.target)) {
        handleShowNavigation(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={navigationRef}>
      <div
        className={classNames(
          "h-full flex justify-center items-center gap-5 cursor-pointer px-[16px] lg:px-10 border-r-0 lg:border-r-[1px] border-grey",
          {
            "bg-black-500": showNavigation,
          }
        )}
        onClick={() => handleShowNavigation(!showNavigation)}
      >
        {showNavigation ? (
          <Image src={IconCloseWhite.src} alt="IconCloseWhite" width={20} height={20} className="text-white" />
        ) : (
          <Image src={IconHamburger.src} alt="IconHamburger" width={20} height={20} />
        )}
        <div className="text-[21px] text-white font-bold hidden lg:block">MENU</div>
      </div>
      <div className={`${styles.slideDownDesktop} ${showNavigation ? styles.showMenu : ""}`}>
        <div className={classNames("relative font-roboto")}>
          <div
            id="menu-bar"
            className={classNames(
              " bg-white py-2 text-black border-r-[1px] border-grey-100 overflow-x-auto",
              styles.menuBar
            )}
          >
            {menu.map((item, index) => (
              <div key={index} className="w-full flex flex-col border-b-[1px] border-light-grey">
                <p className={styles.menuTitle}>{item.title}</p>
                {item.subMenu.map((subItem, subIndex) =>
                  subItem.link ? (
                    <div
                      key={`menu-${index}-${subIndex}`}
                      className={classNames(styles.menuItem1)}
                      onClick={() => handleShowNavigation(false)}
                    >
                      <Link href={subItem.link} target={subItem.name === OEM ? "_blank" : "_self"}>
                        <p className={styles.menuItem}>{subItem.title}</p>
                      </Link>
                    </div>
                  ) : (
                    <div
                      key={`menu-${index}-${subIndex}`}
                      className={classNames(styles.menuItem1, subMenu === subItem.name && "bg-grey-200")}
                      onClick={() => handleClick(subItem.name)}
                    >
                      <p className={styles.menuItem}>{subItem.title}</p>
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
          <NewBikesMenu
            open={subMenu === NEW_BIKES_MENU}
            onOpen={() => setSubMenu(NEW_BIKES_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <UsedBikesMenu
            open={subMenu === USED_BIKES_MENU}
            onOpen={() => setSubMenu(USED_BIKES_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <RoadGearMenu
            open={subMenu === ROAD_GEAR_MENU}
            onOpen={() => setSubMenu(ROAD_GEAR_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <MXGearMenu
            open={subMenu === MX_GEAR_MENU}
            onOpen={() => setSubMenu(MX_GEAR_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <AdventureGearMenu
            open={subMenu === ADVENTURE_GEAR_MENU}
            onOpen={() => setSubMenu(ADVENTURE_GEAR_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <CategotiesMenu
            open={subMenu === CATEGORIES_MENU}
            onOpen={() => setSubMenu(CATEGORIES_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <VehiclesMenu
            open={subMenu === VEHICLES_MENU}
            onOpen={() => setSubMenu(VEHICLES_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <BrandsMenu
            open={subMenu === BRANDS_MENU}
            onOpen={() => setSubMenu(BRANDS_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <ContactMenu
            open={subMenu === CONTACT_MENU}
            onOpen={() => setSubMenu(CONTACT_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
          <MoreInformationMenu
            open={subMenu === MORE_MENU}
            onOpen={() => setSubMenu(MORE_MENU)}
            onClose={() => setSubMenu("")}
            onCloseNavigation={handleShowNavigation}
          />
        </div>
        <div className="w-full bg-black opacity-50 h-full" onClick={() => handleShowNavigation(false)}></div>
      </div>
    </div>
  );
}
