"use client";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

export default function LoadingFreshChat() {
  const pathName = usePathname();

  useEffect(() => {
    const firstPath = pathName.split("/")[1];

    const nonFreshChatPaths = [
      "about",
      "blog",
      "checkout",
      "create-account",
      "login",
      "new-bikes",
      "profile",
      "service",
    ];
    if (document.getElementById("fc_frame")) {
      if (!firstPath || nonFreshChatPaths.includes(firstPath)) {
        document.getElementById("fc_frame").style.display = "none";
      } else {
        document.getElementById("fc_frame").style.display = "block";
      }
    }
  }, [pathName]);

  return null;
}
